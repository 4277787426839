// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FaceguardModelRow } from '../typings';

const data = [
  {
    "id": "UniversalMask3",
    "name": "Universal Mask 3.0",
    "props": {
      "assetId": "UniversalMask3",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": true,
        "Z5": true,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "MJG",
    "name": "Multi-Adjust Jaw Guard",
    "props": {
      "assetId": "MJG",
      "areaCount": 2,
      "price": 32,
      "hasSide": true,
      "hasScrews": false
    },
    "subset": {
      "sportId": {
        "baseball": true,
        "softball": false
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": true,
        "Z5": true,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "UniversalSoftballMask2",
    "name": "Universal Softball Mask 2.0",
    "props": {
      "assetId": "UniversalSoftballMask2",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": false,
        "Z5": true,
        "ALPHA": true,
        "GHOST": false,
        "ProX": false
      }
    }
  },
  {
    "id": "GhostFaceMask",
    "name": "Ghost Face Mask",
    "props": {
      "assetId": "GhostFaceMask",
      "areaCount": 2,
      "price": 35,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "GhostVisionMask",
    "name": "Ghost Vision Mask",
    "props": {
      "assetId": "GhostVisionMask",
      "areaCount": 2,
      "price": 45,
      "hasSide": false,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  },
  {
    "id": "GhostJawGuard",
    "name": "Ghost Jaw Guard",
    "props": {
      "assetId": "GhostJawGuard",
      "areaCount": 2,
      "price": 32,
      "hasSide": true,
      "hasScrews": true
    },
    "subset": {
      "sportId": {
        "baseball": false,
        "softball": true
      },
      "sizeId": {
        "junior": true,
        "senior": true
      },
      "modelId": {
        "ProMax": false,
        "Z5": false,
        "ALPHA": false,
        "GHOST": true,
        "ProX": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FaceguardModelRow[]>> = data;

export type FaceguardModel = typeof typed[number];

export const FACEGUARD_MODEL_INDEX_KEY = "id";
export type FaceguardModelIndexKey = "id";
export type FaceguardModelId = FaceguardModel["id"];

let i = 0;
export const FACEGUARD_MODEL_DICT = {
  "UniversalMask3": typed[i++],
  "MJG": typed[i++],
  "UniversalSoftballMask2": typed[i++],
  "GhostFaceMask": typed[i++],
  "GhostVisionMask": typed[i++],
  "GhostJawGuard": typed[i++]
} as const;

export { typed as FACEGUARD_MODELS };
